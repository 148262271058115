import React, { useState, useContext, useCallback, useEffect, useRef, useMemo } from 'react';
import { ApiContext } from '../../contexts/ApiContext';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Box, Typography, CircularProgress, TextField, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomHeader from './CustomHeader';
import { useAuth0 } from '@auth0/auth0-react';

const StoreMasterTable = ({ initialData, selectedClient, selectedRetailer, multi }) => {
    const [data, setData] = useState([]);
    const [columnWidths, setColumnWidths] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef(null);
    const editedRowsRef = useRef({});
    const { StoreMasterAPI } = useContext(ApiContext);
    const [filters, setFilters] = useState({});
    const [sortModel, setSortModel] = useState([{ field: 'retailer', sort: 'asc' }]);
    const { user } = useAuth0()

    const handleSort = (field) => {
        if (sortModel[0]?.field === field) {
            setSortModel([{ field, sort: sortModel[0].sort === 'asc' ? 'desc' : 'asc' }]);
        } else {
            setSortModel([{ field, sort: 'asc' }]);
        }
    };

    const handleFilterChange = useCallback((field, value) => {
        setFilters(prev => ({ ...prev, [field]: value }));
    }, []);

    const filteredData = useMemo(() => {
        return data.filter(row => {
            return Object.entries(filters).every(([field, value]) => {
                return String(row[field]).toLowerCase().includes(value.toLowerCase());
            });
        });
    }, [filters, data]);

    const useStyles = makeStyles({
        customHeader: {
            backgroundColor: '#F6F7F8',
            color: '#7B7381',
            fontSize: '13px',
            '&:last-child': {
                borderTopRightRadius: 0,
            },
            '&:first-child': {
                borderTopLeftRadius: 0,
            },
        },
        editedRow: {
            backgroundColor: '#C2FFAF',
            '&:hover': {
                backgroundColor: '#C2FFAF !important',
            },
            '&.Mui-selected': {
                backgroundColor: '#C2FFAF !important',
            },
            '&.Mui-selected:hover': {
                backgroundColor: '#C2FFAF !important',
            },
        },
    });
    const classes = useStyles();

    useEffect(() => {
        if (initialData && initialData.length > 0) {
            setData(initialData.map((row, index) => ({ ...row, id: row.id || index })));
            editedRowsRef.current = {};
        }
    }, [initialData]);

    useEffect(() => {
        if (refresh) {
            let selRetailer = selectedRetailer;
            if (multi && selectedRetailer === 'All') {
                selRetailer = multi;
            }
            StoreMasterAPI.get(selectedClient.key, selRetailer)
                .then(res => {
                    setData(res.map((row, index) => ({ ...row, id: row.id || index })));
                    setRefresh(false);
                    editedRowsRef.current = {};
                    alert('Data Updated Successfully');
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    setIsLoading(false);
                });
        }
    }, [refresh]);

    const columns = [
        {
            field: 'retailer', headerName: 'Retailer', flex: 1, headerClassName: 'custom-header', renderHeader: (params) => (
                <CustomHeader
                    column={params.colDef}
                    filterValue={filters['retailer']}
                    onFilterChange={handleFilterChange}
                    sortDirection={sortModel[0]?.field === 'retailer' ? sortModel[0].sort : null}
                    onSortClick={() => handleSort('retailer')}
                    isSortActive={sortModel[0]?.field === 'retailer'}
                />
            ),
            sortable: false,
        },
        {
            field: 'store_code', headerName: 'Source Store Desc', flex: 1.2, headerClassName: 'custom-header', renderHeader: (params) => (
                <CustomHeader
                    column={params.colDef}
                    filterValue={filters['store_code']}
                    onFilterChange={handleFilterChange}
                    sortDirection={sortModel[0]?.field === 'store_code' ? sortModel[0].sort : null}
                    onSortClick={() => handleSort('store_code')}
                    isSortActive={sortModel[0]?.field === 'store_code'}
                />
            ),
            sortable: false,
        },
        {
            field: 'store_number', headerName: 'Store Number', flex: 1, headerClassName: 'custom-header', headerAlign: 'center', align: 'center', renderHeader: (params) => (
                <CustomHeader
                    column={params.colDef}
                    filterValue={filters['store_number']}
                    onFilterChange={handleFilterChange}
                    sortDirection={sortModel[0]?.field === 'store_number' ? sortModel[0].sort : null}
                    onSortClick={() => handleSort('store_number')}
                    isSortActive={sortModel[0]?.field === 'store_number'}
                />
            ),
            sortable: false,
        },
        {
            field: 'store_name', headerName: 'Store Name', flex: 1, headerClassName: 'custom-header', renderCell: (params) => <CustomEditCell {...params} />, headerAlign: 'center', renderHeader: (params) => (
                <CustomHeader
                    column={params.colDef}
                    filterValue={filters['store_name']}
                    onFilterChange={handleFilterChange}
                    sortDirection={sortModel[0]?.field === 'store_name' ? sortModel[0].sort : null}
                    onSortClick={() => handleSort('store_name')}
                    isSortActive={sortModel[0]?.field === 'store_name'}
                />
            ),
            sortable: false,
        },
        {
            field: 'city', headerName: 'City', headerClassName: 'custom-header', renderCell: (params) => <CustomEditCell {...params} />, headerAlign: 'center', renderHeader: (params) => (
                <CustomHeader
                    column={params.colDef}
                    filterValue={filters['city']}
                    onFilterChange={handleFilterChange}
                    sortDirection={sortModel[0]?.field === 'city' ? sortModel[0].sort : null}
                    onSortClick={() => handleSort('city')}
                    isSortActive={sortModel[0]?.field === 'city'}
                />
            ),
            sortable: false,
        },
        {
            field: 'state', headerName: 'State', flex: 0.8, headerClassName: 'custom-header', renderCell: (params) => <CustomEditCell {...params} />, headerAlign: 'center', renderHeader: (params) => (
                <CustomHeader
                    column={params.colDef}
                    filterValue={filters['state']}
                    onFilterChange={handleFilterChange}
                    sortDirection={sortModel[0]?.field === 'state' ? sortModel[0].sort : null}
                    onSortClick={() => handleSort('state')}
                    isSortActive={sortModel[0]?.field === 'state'}
                />
            ),
            sortable: false,
        },
        {
            field: 'country', headerName: 'Country', flex: 1, headerClassName: 'custom-header', renderCell: (params) => <CustomEditCell {...params} />, headerAlign: 'center', renderHeader: (params) => (
                <CustomHeader
                    column={params.colDef}
                    filterValue={filters['country']}
                    onFilterChange={handleFilterChange}
                    sortDirection={sortModel[0]?.field === 'country' ? sortModel[0].sort : null}
                    onSortClick={() => handleSort('country')}
                    isSortActive={sortModel[0]?.field === 'country'}
                />
            ),
            sortable: false,
        },
        {
            field: 'address', headerName: 'Address', flex: 1, headerClassName: 'custom-header', renderCell: (params) => <CustomEditCell {...params} />, headerAlign: 'center', renderHeader: (params) => (
                <CustomHeader
                    column={params.colDef}
                    filterValue={filters['address']}
                    onFilterChange={handleFilterChange}
                    sortDirection={sortModel[0]?.field === 'address' ? sortModel[0].sort : null}
                    onSortClick={() => handleSort('address')}
                    isSortActive={sortModel[0]?.field === 'address'}
                />
            ),
            sortable: false,
        },
        {
            field: 'zip', headerName: 'Zip', flex: 0.8, headerClassName: 'custom-header', renderCell: (params) => <CustomEditCell {...params} />, headerAlign: 'center', renderHeader: (params) => (
                <CustomHeader
                    column={params.colDef}
                    filterValue={filters['zip']}
                    onFilterChange={handleFilterChange}
                    sortDirection={sortModel[0]?.field === 'zip' ? sortModel[0].sort : null}
                    onSortClick={() => handleSort('zip')}
                    isSortActive={sortModel[0]?.field === 'zip'}
                />
            ),
            sortable: false,
        },
        {
            field: 'store_type',
            headerName: 'Store Type',
            flex: 1,
            headerClassName: 'custom-header',
            renderCell: (params) => <CustomEditCell {...params} />,
            headerAlign: 'center',
            renderHeader: (params) => (
                <CustomHeader
                    column={params.colDef}
                    filterValue={filters['store_type']}
                    onFilterChange={handleFilterChange}
                    sortDirection={sortModel[0]?.field === 'store_type' ? sortModel[0].sort : null}
                    onSortClick={() => handleSort('store_type')}
                    isSortActive={sortModel[0]?.field === 'store_type'}
                />
            ),
            sortable: false,
        },
    ];

    useEffect(() => {
        const calculateColumnWidths = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const totalFlex = columns.reduce((sum, column) => sum + (column.flex || 1), 0);
                const newColumnWidths = {};
                columns.forEach(column => {
                    newColumnWidths[column.field] = Math.floor((column.flex || 1) / totalFlex * containerWidth);
                });
                setColumnWidths(newColumnWidths);
            }
        };

        calculateColumnWidths();
        window.addEventListener('resize', calculateColumnWidths);
        return () => window.removeEventListener('resize', calculateColumnWidths);
    }, []);

    const columnsWithWidth = columns.map(column => ({
        ...column,
        width: columnWidths[column.field] || 100, // fallback width
    }));

    const handleSubmit = async () => {
        console.log('Edited Data:', editedRowsRef.current);
        if (Object.keys(editedRowsRef.current).length === 0) {
            alert('No data to update');
            return;
        }

        setIsLoading(true);
        let successCount = 0;
        let failCount = 0;

        const queue = Object.entries(editedRowsRef.current);

        const processQueue = async () => {
            for (const [key, row] of queue) {
                try {
                    await StoreMasterAPI.post(row, user.nickname, selectedClient, selectedRetailer);
                    successCount++;
                } catch (ex) {
                    console.error(`Error updating row ${key}:`, ex);
                    failCount++;
                }
            }
        };

        try {
            await processQueue();

            if (failCount === 0) {
                // alert(`Updates completed successfully.`);
                setRefresh(true);
            } else {
                alert('Update process encountered an error. Please contact Asquad@skyitgroup.com to request changes.');
                editedRowsRef.current = {};
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error in update process:', error);
            alert('Update process encountered an error. Please contact Asquad@skyitgroup.com to request changes.');
            editedRowsRef.current = {};
            setIsLoading(false);
        }
    };

    const CustomEditCell = (props) => {
        const { id, value: initialValue, field, api } = props;
        const [value, setValue] = useState(initialValue);

        const handleChange = (event) => {
            const newValue = event.target.value;
            setValue(newValue);
            if (!editedRowsRef.current[id]) {
                editedRowsRef.current[id] = {};
                editedRowsRef.current[id]['trade_partner_id'] = props.row.trade_partner_id;
                editedRowsRef.current[id]['store_number'] = props.row.store_number;
                editedRowsRef.current[id]['record_id'] = props.row.record_id;
                editedRowsRef.current[id][field] = newValue.toString();
            }
            else {
                if (newValue === initialValue.toString()) {
                    delete editedRowsRef.current[id][field];
                    if (Object.keys(editedRowsRef.current[id]).length === 3) {
                        delete editedRowsRef.current[id];
                    }
                }
                else {
                    editedRowsRef.current[id][field] = newValue;
                }
            }
            api.updateRows([{ id }]);
        };
        let editedVal = null;
        if (id in editedRowsRef.current && field in editedRowsRef.current[id]) {
            editedVal = editedRowsRef.current[id][field];
        }
        if (field === 'store_type') {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Select
                        value={editedVal || value}
                        onChange={handleChange}
                        size="small"
                        displayEmpty
                        sx={{
                            height: '28px',
                            fontSize: '13px',
                            width: '100%',
                            fontWeight: 'normal',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                borderRadius: 0,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'primary.main',
                            }
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    '& .MuiMenuItem-root': {
                                        fontSize: '13px',
                                        fontWeight: 'normal',
                                    },
                                },
                            },
                        }}
                    >
                        {["Undefined", "Full Line", "Outlet", "Direct"].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            );
        }

        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <TextField
                    variant="outlined"
                    size="small"
                    value={editedVal || value}
                    onChange={handleChange}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '28px',
                            '& fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                borderRadius: 0,
                            },
                            '&:hover fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'primary.main',
                            },
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: '4px 8px',
                            fontSize: '13px',
                        },
                    }}
                />
            </Box>
        );
    };

    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            bgcolor: '#fff',
            boxShadow: 3,
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" fontWeight="bold" sx={{ padding: '0 0 0 15px' }}>
                Store Master
            </Typography>
            <Box ref={containerRef} sx={{ flexGrow: 1, width: '100%', height: 'calc(100% - 120px)' }}>
                <DataGrid
                    rows={filteredData}
                    columns={columnsWithWidth}
                    columnHeaderHeight={60}
                    rowHeight={40}
                    getRowClassName={(params) => editedRowsRef.current[params.id] ? classes.editedRow : ''}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    disableSelectionOnClick
                    loading={isLoading}
                    autoWidth
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    disableColumnMenu
                    disableColumnSelector
                    components={{
                        EditCell: CustomEditCell,
                    }}
                    autoHeight
                    hideFooterSelectedRowCount
                    sortingOrder={['asc', 'desc']}
                    sx={{
                        '& .MuiDataGrid-row': {
                            maxHeight: 'none !important',
                        },
                        '& .MuiDataGrid-row:hover': {
                            backgroundColor: 'inherit',
                        },
                        '& .MuiDataGrid-row.Mui-selected': {
                            backgroundColor: 'inherit',
                        },
                        '& .MuiDataGrid-row.Mui-selected:hover': {
                            backgroundColor: 'inherit',
                        },
                        '& .MuiDataGrid-row:focus, & .MuiDataGrid-cell:focus': {
                            outline: 'none !important',
                        },
                        '.MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold !important',
                            overflow: 'visible !important'
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                        "& .MuiDataGrid-columnHeader:focus": {
                            outline: "none !important",
                        },
                        '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-columnHeaderTitle': {
                            color: 'black',
                        },
                        '& .MuiDataGrid-row': {
                            transition: 'none !important',
                        },
                        '& .MuiCircularProgress-root': {
                            color: '#54B948',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#F6F7F8',
                        },
                        '& .MuiDataGrid-iconButtonContainer': {
                            display: 'none !important',
                        },
                        '& .MuiDataGrid-sortIcon': {
                            display: 'none !important',
                        },
                        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                            outline: 'none !important',
                        },
                        '& .MuiDataGrid-columnHeader:focus-within': {
                            outline: 'none !important',
                        },
                    }}
                    classes={{
                        columnHeader: classes.customHeader,
                        columnHeaderSorted: classes.sortedColumnHeader,
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isLoading || data.length === 0}
                    sx={{
                        backgroundColor: '#5BBC4F',
                        '&:hover': {
                            backgroundColor: '#4CA83D',
                        },
                    }}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    );
};

export default React.memo(StoreMasterTable);
