import { Grid } from '@material-ui/core';
import { Modal, Box, IconButton } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
// import './Dashboard.css';
import { HeaderContext } from "../../components/dashboard";
import HistoryTable from './HistoryTable';

function StoreMaster() {
    const { selectedWED, fileData, selectedClient, selectedRetailer, historyData, historyOriginalData } = useContext(HeaderContext)
    const { user } = useAuth0()
    const [loading, setLoading] = useState(false);
    
    let fileData1 = historyData;

    let tempData = fileData1;

    return (
        // <>Loading...</>
        tempData &&
        <Grid item xl={12} lg={12} md={12} sm={12} style={{ paddingBottom: '0px' }}>
            <HistoryTable fileData={tempData} />
        </Grid>

    );
}

export default StoreMaster;