import React from 'react';
import {
    Box, TextareaAutosize, InputLabel, FormControl, Radio,
    Grid, Checkbox, MenuItem, Select, Stack, OutlinedInput, FormControlLabel, RadioGroup, FormHelperText, Button, Typography
} from '@mui/material';
import ValidateEmail from './Email';
import { useContext, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { ApiContext } from '../../contexts/ApiContext';
import { HeaderContext } from '../../components/dashboard';

export default function EmailForm({ retailerDataProp, displayClient, handleCloseEmail }) {
    const { user } = useAuth0()
    const [editInputs, setEditInputs] = React.useState({
        email: '',
        subject: `Sales data for ${retailerDataProp.weekend} needs to be submitted to SKYBAZAAR`,
        body:
            `Hi ${retailerDataProp.retailer} Team,

The POS data for week-ending ${retailerDataProp.weekend} has not been submitted to SKYBAZAAR yet. 
Can you please login here and submit the file?
        
Best,
${user.name}`
    });
    const [emailValidation, setEmailValidation] = useState(true);
    const [sending, setSending] = useState(false);
    const { sendEmailAPI } = useContext(ApiContext);
    const { selectedWED, fileData, selectedClient, selectedRetailer, showEmail } = useContext(HeaderContext)


    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (name === 'email') {
            if (!ValidateEmail(value) || value.split(/,|;|\s+/).length > 1) {
                setEmailValidation(false);
            }
            else if (emailValidation === false) {
                setEmailValidation(true)
            }
        }
        setEditInputs(values => ({ ...values, [name]: value }))
    }

    const handleSendEmail = async () => {
        try {
            setSending(true);
            sendEmailAPI(user.email, editInputs.email, editInputs.subject, editInputs.body, selectedClient.key, selectedRetailer, user.name)
                .then((res) => {
                    setSending(false);
                    alert("Email sent successfully")
                    handleCloseEmail();
                    console.log("Email sent successfully")
                });
        } catch (error) {
            alert("Failed to send email. Please try again.")
            console.error('Error sending email:', error);
            // setError('Failed to send email. Please try again.');
        }

    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="h6" component="h2" mb={2} >
                Send Reminder Email to your Retail Buyer
            </Typography>
            <Box
                sx={{
                    p: 3, // Add padding to the content box
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                        <InputLabel sx={{ color: 'black', mr: 2 }}>Send to:</InputLabel>
                        <OutlinedInput size="small" name="email" placeholder="Enter email address"
                            error={!emailValidation}
                            onChange={handleInputChange}
                        />
                        {!emailValidation ? <FormHelperText error sx={{ marginLeft: 1 }}>Email format is wrong</FormHelperText> : <></>}
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                        <InputLabel sx={{ color: 'black', marginRight: 2, whiteSpace: 'nowrap' }}>Email Subject:</InputLabel>
                        <OutlinedInput
                            size="small"
                            name="subject"
                            value={editInputs.subject}
                            onChange={handleInputChange}
                            sx={{ flexGrow: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel sx={{ color: 'black', marginTop: '10px' }}>Email Body:</InputLabel>
                        <Box sx={{
                            width: '100%', height: 180, overflowY: 'auto',
                            scrollbarWidth: 'none', /* Hides track */
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(120, 120, 120, 0.5)', /* Gray thumb */
                                borderRadius: '6px', /* Rounded corners for thumb */
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: 'rgba(120, 120, 120, 0.7)', /* Darken thumb on hover */
                            },
                        }}>
                            <OutlinedInput
                                size="small"
                                name="body"
                                multiline
                                minRows={6}
                                onChange={handleInputChange}
                                value={editInputs.body}

                                sx={{ width: '100%' }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Button variant="contained" color="primary" type="submit" sx={{
                mb: 3, minWidth: '15%', backgroundColor: '#5BBC4F', '&:hover': {
                    backgroundColor: '#4CA83D',
                },
            }} disabled={editInputs.email.length === 0 || !emailValidation || sending}
                onClick={handleSendEmail}>
                {sending ? 'Sending...' : 'Send'}
            </Button>
        </Box>
    )
}
