import RemainFileTable from "./RemainFileTable";
import UploadedFileTable from './UploadedFileTable';
import { Grid } from '@material-ui/core';
import { Modal, Box, IconButton } from "@mui/material";
import { useState, useContext } from "react";
import UploadPage from "../uploadPage/uploadPage";
import ProgressOutline from "../progress/progressPageOutline";
import { useAuth0 } from '@auth0/auth0-react';
import './Dashboard.css';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import { HeaderContext } from "../../components/dashboard";
import { ApiContext } from "../../contexts/ApiContext";
import EmailPage from "../email/emailPage";

function Dashboard() {
  const [popUp, setPopUp] = useState({ retailerData: "", isOpen: false });
  const [emailPopUp, setEmailPopUp] = useState({ retailerData: "", isOpen: false });
  const [progressPageId, setProgressPageId] = useState(null);
  const { selectedWED, fileData, selectedClient, selectedRetailer, showEmail } = useContext(HeaderContext)
  const [submitted, setSumitted] = useState(false);
  const [stillLoading, setStillLoading] = useState(true);
  const [abortController] = useState(new AbortController());
  const { clientLogAPI } = useContext(ApiContext);
  const { user } = useAuth0()

  let fileData1 = fileData;
  const handleClose = () => {
    if (progressPageId) {
      if (window.confirm("Do you really want to close this? Page will reload") === true) {
        if (stillLoading || !submitted) {
          let printOut = ""
          if (stillLoading) {
            // printOut = `${user.nickname}, ${popUp.retailerData.client_id}, ${popUp.retailerData.retailer}, user cancelled while file was being validated`;
            printOut = `user cancelled while file was being validated`;
          }
          else if (!submitted) {
            // printOut = `${user.nickname}, ${popUp.retailerData.client_id}, ${popUp.retailerData.retailer}, user cancelled after file validation was completed`;
            printOut = `user cancelled after file validation was completed`;
          }
          try {
            clientLogAPI(user.nickname, popUp.retailerData.client_id, popUp.retailerData.retailer, printOut)
              .then((res) => {
                console.log("sent to log")
              });
          } catch (error) {
            console.log(error)
          }
        }

        window.location.reload();
      }
    }
    else {
      setPopUp({ retailerData: "", isOpen: false })
    }
  }

  const handleCloseEmail = () => {
    setEmailPopUp({ retailerData: "", isOpen: false })
  }

  const dashboardPopUpTrigger = (row, name) => {
    let data2 = { retailerData: row, isOpen: true, wsclient: null }
    if (name === 'email') {
      setEmailPopUp((data1) => ({
        ...data1,
        ...data2
      }))
    }
    else {
      setPopUp((data1) => ({
        ...data1,
        ...data2
      }))
    }
  }

  let tempData = fileData1.slice();
  let od = fileData1.slice();


  if (selectedWED && selectedWED !== "All") {
    od.forEach(o => {
      if (o['weekend'].toString() === selectedWED.toString()) {
        tempData.push(o)
      }
    });
  }

  if (selectedClient && selectedClient !== "All") {
    od.forEach(o => {
      if (o['client_id'] === selectedClient) {
        tempData.push(o)
      }
    });
  }

  if (selectedRetailer && selectedRetailer.retVal !== "All") {
    od.forEach(o => {
      if (selectedRetailer && o['retailer'] === selectedRetailer.retName) {
        tempData.push(o)
      }
    });
  }

  return (
    fileData1 && Array.isArray(fileData1) ?
      <>
        <Grid item xl={12} lg={12} md={12} sm={12} style={{ paddingBottom: '10px' }}>
          <RemainFileTable fileData={tempData.sort((a, b) => a.retailer.localeCompare(b.retailer) || new Date(b.weekend) - new Date(a.weekend)).filter(x => x.statusState !== 'Uploaded' && x.statusDay !== 0)} popUpTrigger={(row, name) => dashboardPopUpTrigger(row, name)} showEmail={showEmail}/>
        </Grid>

        <Grid item style={{ marginTop: "15px" }} lg={12} md={12} sm={12}  >
          <UploadedFileTable fileData={tempData.sort((a, b) => a.retailer.localeCompare(b.retailer) || new Date(b.weekend) - new Date(a.weekend)).filter(x => x.statusState === 'Uploaded')} popUpTrigger={(row) => dashboardPopUpTrigger(row)} />
        </Grid>
        <Modal
          open={popUp.isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{}}
        >
          <Box style={{
            width: 770, position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            outline: 'none',
            backgroundColor: '#e4ebf5',
            borderRadius: 5
          }}>
            <Box sx={{
              display: 'flex',
              paddingRight: 2,
              flexDirection: 'row-reverse'
            }}>
              <Avatar onClick={() => { handleClose(); }} sx={
                {
                  '&:hover': {
                    backgroundColor: '#EEEEEE',
                  }, color: '#BFBFBF', bgcolor: '#FFFFFF', position: 'absolute', right: '-20px', top: '-20px', border: '2px solid'
                }}>
                <CloseIcon />
              </Avatar>
            </Box>
            {!progressPageId ? <UploadPage retailerDataProp={popUp.retailerData} displayClient={selectedClient} setProgressPageId={setProgressPageId} /> : <ProgressOutline retailerDataProp={popUp} displayClient={selectedClient} progressPageId={progressPageId} abortController={abortController} setStillLoading={setStillLoading} setSumitted={setSumitted} />}
          </Box>
        </Modal>
        <Modal
          open={emailPopUp.isOpen}
          onClose={handleCloseEmail}
          sx={{}}
        >
          <Box style={{
            width: 770, position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            outline: 'none',
            backgroundColor: '#e4ebf5',
            borderRadius: 5,
          }}>
            <Box sx={{
              display: 'flex',
              paddingRight: 2,
              flexDirection: 'row-reverse'

            }}>
              <Avatar onClick={() => { handleCloseEmail(); }} sx={
                {
                  '&:hover': {
                    backgroundColor: '#EEEEEE',
                  }, color: '#BFBFBF', bgcolor: '#FFFFFF', position: 'absolute', right: '-20px', top: '-20px', border: '2px solid'
                }}>
                <CloseIcon />
              </Avatar>
              <EmailPage retailerDataProp={emailPopUp.retailerData} displayClient={selectedClient} handleCloseEmail={handleCloseEmail} />
            </Box>
          </Box>
        </Modal>
      </>
      : <>Loading...</>
  );
}

export default Dashboard;