/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Header from './header';
import Nav from './nav';
import { useEffect, useState, createContext, useContext } from "react";
import { ApiContext, OverlayContext } from '../../contexts/context';
import { useAuth0 } from '@auth0/auth0-react';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 76;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export const HeaderContext = createContext();
const AllSelObj = { key: 'All', val: 'All' }
// ----------------------------------------------------------------------

function DashboardLayout() {

  const { OverlayAction } = useContext(OverlayContext);
  const [clientList, setClientList] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [storeMasterData, setStoreMasterData] = useState(null);
  const [storeMasterOriginalData, setStoreMasterOriginalData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [historyOriginalData, setHistoryOriginalData] = useState(null);
  const [selectedRetailer, setSelectedRetailer] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedWED, setSelectedWED] = useState(AllSelObj);
  const [multi, setMulti] = useState(null);
  const [showEmail, setShowEmail] = useState(false);
  const { user } = useAuth0()

  const { GetClientAPI, GetTableDataAPI, StoreMasterAPI, GetHistoryDataAPI } = useContext(ApiContext);

  function filterKeysFromArray(originalArray, keysToKeep) {
    const seen = new Set();

    return originalArray
      .map(obj => {
        const newObj = {};
        keysToKeep.forEach(key => {
          if (obj.hasOwnProperty(key)) {
            newObj[key] = obj[key];
          }
        });
        return newObj;
      })
      .filter(obj => {
        const key = JSON.stringify(obj);
        return seen.has(key) ? false : seen.add(key);
      });
  }

  useEffect(async () => {
    let mounted = true
    GetClientAPI()
      .then(res => {
        if (mounted) {
          setClientList(res)
          if (res[0]['email']) {
            setShowEmail(true)
          }
          if ((res[0]['flag'] === 'retailer' && res.map(d => d.retailer).filter((val, index, self) => self.indexOf(val) === index).length === 1) || (res[0]['flag'] === 'one' && res.length === 1)) {    // when user has only one retailer to see
            setSelectedRetailer(res[0]['retailer'])
            if (res[0]['flag'] === 'one') {     // if there is only one client choice with one retailer
              setSelectedClient({ key: res[0]['client_id'], val: res[0]['client_name'] })
            }
            else {
              setSelectedClient(AllSelObj)    // many clients to choose 
            }
          }
          else if (res[0]['flag'] === 'one') {      // only one client
            setSelectedClient({ key: res[0]['client_id'], val: res[0]['client_name'] })
            setSelectedRetailer('All')
          }
          else {        // many clients and retailers = SkyInternal
            if (res[0]['flag'] === 'retailer') {     // multi retailers
              setSelectedClient(AllSelObj)
              setSelectedRetailer(res.map(d => d.retailer).filter((val, index, self) => self.indexOf(val) === index).toString())
              setMulti(res.map(d => d.retailer).filter((val, index, self) => self.indexOf(val) === index).toString())
            }
            else {
              setSelectedClient(AllSelObj)
              setSelectedRetailer('All')
            }
          }
        }
      }).catch(function () {
      })
  }, [])

  useEffect(async () => {

    if (selectedClient && selectedRetailer) {
      let mounted = true
      let selRetailer = selectedRetailer
      if (multi && selectedRetailer === 'All') {
        selRetailer = multi
      }

      if (!originalData && !fileData) {
        GetTableDataAPI(selectedClient.key, selectedWED.key, selRetailer)
          .then(res => {
            if (mounted) {
              setOriginalData(res)
            }
          }).catch(function (error) {
            OverlayAction.stop();
            console.log(error)
          })
      }
      else {
        let tempData = [];
        if (selRetailer.split(',').length > 1) {
          let retailerArray = selRetailer.split(',');
          tempData = originalData.filter(d => retailerArray.includes(d.retailer));
        }
        else {
          tempData = selRetailer !== 'All' ? originalData.filter(d => d.retailer === selRetailer) : originalData;
        }
        tempData = selectedClient.key !== 'All' ? tempData.filter(d => d.client_id === selectedClient.key) : tempData;
        tempData = selectedWED.key !== 'All' ? tempData.filter(d => d.weekend.toString() === selectedWED.key.toString()) : tempData;
        setFileData(tempData)
        if (window.location.pathname === '/upload') {
          setClientList(filterKeysFromArray(originalData, ['client_id', 'client_name', 'retailer']))
        }
      }

      if (!storeMasterOriginalData && !storeMasterData) {
        StoreMasterAPI.get(selectedClient.key, selRetailer, user.nickname)
          .then(res => {
            if (mounted) {
              setStoreMasterOriginalData(res)
            }
          }).catch(function (error) {
            OverlayAction.stop();
            console.log(error)
          })
      }
      else {
        let tempData = [];
        if (selRetailer.split(',').length > 1) {
          let retailerArray = selRetailer.split(',');
          tempData = storeMasterOriginalData.filter(d => retailerArray.includes(d.retailer));
        }
        else {
          tempData = selRetailer !== 'All' ? storeMasterOriginalData.filter(d => d.retailer === selRetailer) : storeMasterOriginalData;
        }
        tempData = selectedClient.key !== 'All' ? tempData.filter(d => d.client_id === selectedClient.key) : tempData;
        setStoreMasterData(tempData)
        if (window.location.pathname === '/storemaster') {
          setClientList(filterKeysFromArray(storeMasterOriginalData, ['client_id', 'client_name', 'retailer']))
        }
      }

      if (!historyOriginalData && !historyData) {
        GetHistoryDataAPI(selectedClient.key, selectedWED.key, selRetailer, user.nickname)
          .then(res => {
            if (mounted) {
              setHistoryOriginalData(res)
            }
          }).catch(function (error) {
            OverlayAction.stop();
            console.log(error)
          })
      }
      else {
        let tempData = [];
        if (selRetailer.split(',').length > 1) {
          let retailerArray = selRetailer.split(',');
          tempData = historyOriginalData.filter(d => retailerArray.includes(d.retailer));
        }
        else {
          tempData = selRetailer !== 'All' ? historyOriginalData.filter(d => d.retailer === selRetailer) : historyOriginalData;
        }
        tempData = selectedClient.key !== 'All' ? tempData.filter(d => d.client_id === selectedClient.key) : tempData;
        tempData = selectedWED.key !== 'All' ? tempData.filter(d => d.weekend.toString() === selectedWED.key.toString()) : tempData;
        setHistoryData(tempData)
        if (window.location.pathname === '/history') {
          setClientList(filterKeysFromArray(historyOriginalData, ['client_id', 'client_name', 'retailer']))
        }
      }
    }

  }, [selectedClient, selectedWED, selectedRetailer])

  useEffect(() => {
    if (originalData && !fileData) {
      setFileData(originalData)
      OverlayAction.stop();
    }
  }, [originalData])

  useEffect(() => {
    if (storeMasterOriginalData && !storeMasterData) {
      setStoreMasterData(storeMasterOriginalData)
      OverlayAction.stop();
    }
  }, [storeMasterOriginalData])

  useEffect(() => {
    if (historyOriginalData && !historyData) {
      setHistoryData(historyOriginalData)
      OverlayAction.stop();
    }
  }, [historyOriginalData])



  return (
    fileData && clientList &&
    <StyledRoot>
      <HeaderContext.Provider value={{ clientList, fileData, originalData, storeMasterData, storeMasterOriginalData, selectedWED, setSelectedWED, selectedClient, setSelectedClient, selectedRetailer, setSelectedRetailer, historyOriginalData, historyData, showEmail, multi }}>
        <Header />
        <Nav />
        <Main>
          <Outlet context={[selectedClient, selectedWED, selectedRetailer, originalData, fileData]} />
        </Main>
      </HeaderContext.Provider>
    </StyledRoot>
  );
}

export default DashboardLayout;