/* eslint-disable react-hooks/exhaustive-deps */
import { styled, useTheme } from '@mui/material/styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Divider, Box, Tooltip, Typography, TableRow, TablePagination, TableHead, TableContainer, TableBody, Table, TableCell, TableSortLabel, tableCellClasses, IconButton, Chip, Stack } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };
    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>

            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>

        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const StickyTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgb(246, 247, 248)',
        color: theme.palette.common.white,
        left: 0,
        position: "sticky",
        zIndex: 1,
        padding: 0,
        width: '15%'
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: "white",
        left: 0,
        position: "sticky",
        //zIndex: theme.zIndex.appBar + 1,
        padding: 0
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        fontWeight: 'bold',
        color: '#808080',
        fontSize: '13px',
        '& svg': {
            marginRight: '-15px'
        }
    },
    [`&.${tableCellClasses.body}`]: {
        // fontWeight: 'bold',
        // [theme.breakpoints.down('xl')]: {
        //     fontWeight: 'bold',
        // },
    },
}));

function descendingComparator(a, b, orderBy) {
    let tempA = a[orderBy] ? a[orderBy] : ""
    let tempB = b[orderBy] ? b[orderBy] : ""
    if (tempB < tempA) {
        return -1;
    }
    if (tempB > tempA) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'retailer',
        position: true,
        label: 'Retailer',
    },
    {
        id: 'client_name',
        position: true,
        label: 'Brand',
    },
    {
        id: 'weekend',
        position: true,
        label: 'Week-Ending',
    },
    {
        id: 'statusState',
        position: false,
        label: 'SKYPAD Status',
    },
    {
        id: 'action',
        position: false,
        label: 'Actions',
    },
    {
        id: 'user',
        position: false,
        label: 'Submitted By',
    },

    {
        id: 'uploaded_time',
        position: false,
        label: 'Date',
    },
    {
        id: 'statusDay',
        position: false,
        label: 'Due (Days)',
    },

];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <StickyTableCell>
                    {headCells.map((headCell, index) => {
                        if (index <= 2) {
                            return (
                                <>
                                    <StyledTableCell
                                        sx={{ backgroundColor: 'transparent !important', width: '36.6%' }}
                                        key={headCell.id}
                                        align={headCell.position ? 'left' : 'center'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        {headCell.id === 'action' ?
                                            headCell.label
                                            :
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                                sx={
                                                    {
                                                        '&.MuiTableSortLabel-root:hover': {
                                                            backgroundColor: 'transparent !important',
                                                        }
                                                    }
                                                }
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        }
                                    </StyledTableCell>
                                </>
                            )
                        }
                    })}
                </StickyTableCell>
                {headCells.map((headCell, index) => {
                    if (index > 2) {
                        return (
                            <>
                                <StyledTableCell
                                    key={headCell.id}
                                    align={headCell.position ? 'left' : 'center'}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    {headCell.id === 'action' ?
                                        headCell.label
                                        :
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                            sx={
                                                {
                                                    '&.MuiTableSortLabel-root:hover': {
                                                        backgroundColor: 'transparent !important',
                                                    }
                                                }
                                            }
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    }
                                </StyledTableCell>
                            </>
                        )
                    }
                })}

            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function RemainFileTable({ popUpTrigger, fileData, showEmail }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('retailer');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const unuploaded = <Typography variant='body2' sx={{ padding: '2px', bgcolor: '#FF5B5B', borderRadius: 1, fontWeight: 'bold', color: '#FFFFFF', textAlign: 'center' }} >
        Not Submitted
    </Typography>

    const pending = <Typography variant='body2' sx={{ padding: '2px', bgcolor: '#FFA319', borderRadius: 1, fontWeight: 'bold', color: '#FFFFFF', textAlign: 'center' }} >
        Submitted
    </Typography>

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChoosedRow = (row, button) => {
        popUpTrigger(row, button)
    };

    const handleClickInfo = () => {
        console.info('You clicked the delete icon.');
    };

    useEffect(async () => {
        setPage(0)
    }, [fileData])

    // useEffect(async () => {
    //     // sorting table in All Uploaded Files
    //     let tempData = fileData
    //     let rl = tempData.map(d => d.retailer).filter((val, index, self) => self.indexOf(val) === index);
    //     let wedl = tempData.map(d => d.weekend).filter((val, index, self) => self.indexOf(val) === index);
    //     setFileData({ uploadedGrid: tempData, retailerList: rl, wedList: wedl, previousRetailer: "All", previousWeekend: "All" })
    // }, [fileData])

    return (
        fileData ?
            <>
                <Box sx={{ bgcolor: '#fff', boxShadow: 3, borderRadius: 1 }}>
                    <Stack direction="row" spacing={1} sx={{ padding: '0px 0px 0px 15px' }}>
                        <Typography variant="h6" gutterBottom component="div">
                            Remaining Files to Submit
                        </Typography>
                        <Tooltip title='These files are missing from SKYPAD. Once submitted, SKYPAD status will change to “Submitted” and file will be processed within one business day in SKYPAD.' placement='right' arrow>
                            <Chip
                                label={fileData.filter(data => data.statusState === "Not Submitted").length}
                                sx={{ fontWeight: 'bold', marginTop: '5px !important' }}
                                size="small"
                                color="primary"
                                onDelete={handleClickInfo}
                                deleteIcon={<InfoIcon />}
                            />
                        </Tooltip>
                    </Stack>
                    <Divider />
                    <TableContainer>
                        <Table sx={{ minWidth: '100%' }} size="small" >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            {fileData.length !== 0 ?
                                <TableBody>
                                    {
                                        (rowsPerPage > 0
                                            ? stableSort(Object.values(fileData), getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : stableSort(Object.values(fileData), getComparator(order, orderBy))
                                        ).map((item, row) => (
                                            <TableRow hover key={row}>
                                                <StickyTableCell>
                                                    <StyledTableCell component="th" scope="row" sx={{ minWidth: 160, borderBottom: 0 }}>
                                                        {item.retailer}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row" sx={{ minWidth: 160, borderBottom: 0 }}>
                                                        {item.client_name}
                                                    </StyledTableCell>
                                                    <StyledTableCell sx={{ minWidth: 140, borderRight: '2px solid rgba(241, 243, 244, 1) !important', height: '40px', borderBottom: 0 }}>
                                                        {item.weekend}
                                                    </StyledTableCell>
                                                </StickyTableCell>
                                                <StyledTableCell align='center' sx={{ minWidth: 160, width: 0 }}>
                                                    {item.statusState === "Submitted" ? pending : unuploaded}
                                                </StyledTableCell>

                                                <StyledTableCell align="center" sx={{ minWidth: 90, width: 0 }}>
                                                    {showEmail && item.statusState !== "Submitted" ? <div>
                                                        <Tooltip style={{ marginLeft: 2 }} title={<>
                                                            Upload file
                                                        </>} placement='bottom' arrow>

                                                            <UploadFileIcon onClick={() => handleChoosedRow(item, "upload")} />
                                                        </Tooltip>
                                                        <Tooltip style={{ marginLeft: 2 }} title={<>
                                                            Email
                                                        </>} placement='bottom' arrow>
                                                            <MailOutlineIcon onClick={() => handleChoosedRow(item, "email")} />
                                                        </Tooltip>
                                                    </div> :
                                                        <div>
                                                            <Tooltip style={{ marginLeft: "-40%" }} title={<>
                                                                Upload file
                                                            </>} placement='bottom' arrow>
                                                                <UploadFileIcon onClick={() => handleChoosedRow(item, "upload")} />
                                                            </Tooltip>
                                                        </div>}
                                                </StyledTableCell>
                                                <StyledTableCell align='center' sx={{ minWidth: 160 }}>

                                                    {item.user}
                                                </StyledTableCell>

                                                <StyledTableCell align='center' sx={{ minWidth: 175 }}>

                                                    {item.uploaded_time}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" sx={{ minWidth: 100 }}>
                                                    {item.statusDay}
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}

                                </TableBody> :
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={8} align="center" style={{ color: 'gray' }}>
                                            No files to display
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                    <Box>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={Object.keys(fileData).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{ maxHeight: '42px', overflow: 'hidden' }}
                        />
                    </Box>
                </Box>
            </> : <></>
    );
}