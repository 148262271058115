import { Grid } from '@material-ui/core';
import { Modal, Box, IconButton } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import './Dashboard.css';
import { HeaderContext } from "../../components/dashboard";
import { ApiContext } from "../../contexts/ApiContext";
import StoreMasterTable from './StoreMasterTable';

function StoreMaster() {
    const { selectedWED, fileData, selectedClient, selectedRetailer, showEmail, storeMasterData, storeMasterOriginalData, multi } = useContext(HeaderContext)
    const { StoreMasterAPI } = useContext(ApiContext);
    const { user } = useAuth0()
    // const [data, setData] = useState([]);

    // useEffect(async () => {
    //     let mounted = true
    //     setLoading(true)
    //     StoreMasterAPI.get()
    //         .then(res => {
    //             if (mounted) {
    //                 setData(res)
    //                 setLoading(false)
    //             }
    //         }).catch(function () {
    //         })
    // }, [])

    // useEffect(async () => {
    //     if (storeMasterData && storeMasterData.length > 0) {
    //         setLoading(false)
    //     }
    // }, [storeMasterData])

    // let fileData1 = storeMasterData;

    // let tempData = fileData1.slice();
    // let od = fileData1.slice();

    // if (selectedClient && selectedClient.key !== "All") {
    //     od.forEach(o => {
    //         if (o['client_id'] === selectedClient.key) {
    //             tempData.push(o)
    //         }
    //     });
    // }

    // if (selectedRetailer && selectedRetailer !== "All") {
    //     od.forEach(o => {
    //         if (selectedRetailer && o['retailer'] === selectedRetailer) {
    //             tempData.push(o)
    //         }
    //     });
    // }
    let fileData1 = storeMasterData;

    let tempData = fileData1;

    return (
        // <>Loading...</>
        tempData &&
        <Grid item xl={12} lg={12} md={12} sm={12} style={{ paddingBottom: '0px' }}>
            <StoreMasterTable initialData={tempData} selectedClient={selectedClient} selectedRetailer={selectedRetailer} multi={multi}/>
        </Grid>

    );
}

export default StoreMaster;